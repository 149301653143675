import {Dialog} from '@headlessui/react'
import {overrideTailwindClasses} from 'tailwind-override'
import clsx from 'clsx'

export const Modal = ({title = null, body = null, footer = null, className = '', ...props}) => {
  const dialogClasses = overrideTailwindClasses(
    clsx(
      'inline-block w-full max-w-md p-3 md:p-6 my-8 lg:px-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl',
      props.large && 'max-w-none w-full md:w-3/4 max-w-screen-xl',
      props.medium && 'max-w-none w-full md:w-3/4 max-w-screen-lg',
      className
    )
  )
  return (
    <Dialog
      className='fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm'
      open={props.open}
      onClose={() => (props.onClose ? props.onClose() : {})}
      static={props.static || false}
    >
      <div className='flex items-center justify-center min-h-screen'>
        <div className='fixed inset-0 bg-black opacity-40' />
        <Dialog.Panel className={dialogClasses}>
          {title}
          {body && (
            <Dialog.Description as='div' className='mt-2 mb-2'>
              {body}
            </Dialog.Description>
          )}
          {footer && <>{footer}</>}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
