import axios from 'axios'
import {useReducer} from 'react'
import {useForm} from 'react-hook-form'

import {Button} from '@/components/Button'
import {Spinner} from '@/components/design-system/Spinner'
import {Modal} from '@/components/modals/BaseModal'
import {getBaseUrl} from '@/lib/auth/utils'
import {XMarkIcon} from '@heroicons/react/24/outline'

type InitialState = {
  step: number
  validating: boolean
  error: string | undefined
}

type ReducerActions =
  | {type: 'setError'; payload: string}
  | {type: 'setStep'; payload: number}
  | {type: 'setValidating'; payload: boolean}
  | {type: 'setMultiple'; payload: Partial<InitialState>}

const initialState: InitialState = {
  step: 1,
  validating: false,
  error: undefined,
}

const reducer = (state: InitialState, action: ReducerActions) => {
  switch (action.type) {
    case 'setError': {
      return {...state, error: action.payload}
    }
    case 'setStep': {
      return {...state, step: action.payload}
    }
    case 'setValidating': {
      return {...state, validating: !state.validating}
    }
    case 'setMultiple': {
      return {...state, ...action.payload}
    }
    default: {
      return {...state}
    }
  }
}

const DialogTitle = ({
  state,
  dispatch,
  onClose,
}: {
  state: InitialState
  dispatch: React.Dispatch<ReducerActions>
  onClose: () => void
}) => {
  const handleClose = () => {
    if (state.validating) return
    dispatch({type: 'setMultiple', payload: initialState})
    return onClose()
  }
  return (
    <div className='relative flex items-center justify-center mb-8'>
      <p className='text-xl'>Password Reset</p>
      <XMarkIcon className='absolute text-gray-400 cursor-pointer -right-4 -top-4 w-9 h-9' onClick={handleClose} />
    </div>
  )
}

const DialogFooter = ({
  state,
  dispatch,
  onClose,
}: {
  state: InitialState
  dispatch: React.Dispatch<ReducerActions>
  onClose: () => void
}) => {
  return (
    <div className='flex justify-end'>
      {state.step === 2 ? (
        <Button
          medium
          onClick={() => {
            dispatch({type: 'setMultiple', payload: initialState})
            onClose()
          }}
        >
          Close
        </Button>
      ) : (
        <Button
          medium
          type='submit'
          form='reset-password-form'
          className='inline-flex items-center'
          disabled={state.validating}
        >
          Send Email {state.validating && <Spinner className='ml-2 text-gray-800' />}
        </Button>
      )}
    </div>
  )
}

const DialogBody = ({state, dispatch}: {state: InitialState; dispatch: React.Dispatch<ReducerActions>}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const onSubmit = async (data: any) => {
    dispatch({type: 'setMultiple', payload: {validating: true, error: undefined}})
    const result = await axios.post(
      `${getBaseUrl()}/account/api/reset-password/`,
      {email: data.emailInput},
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    )
    if (result.data?.success === false) {
      return dispatch({type: 'setMultiple', payload: {validating: false, error: result.data?.message}})
    }
    return dispatch({type: 'setMultiple', payload: {validating: false, error: undefined, step: 2}})
  }

  if (state.step === 2) {
    return <p className='text-gray-600'>{'Check your email to view instructions for resetting your password.'}</p>
  }

  return (
    <div>
      <p className='text-gray-600'>
        {
          'If you have already purchased courses on www.knowledgecoop.com, enter the same email and password used during checkout. Otherwise, enter your email address below and we will send you instructions for resetting your password.'
        }
      </p>
      {state.error && <div className='mt-4 text-sm text-red-500'>{state.error}</div>}
      <form id={'reset-password-form'} onSubmit={handleSubmit(onSubmit)}>
        <div className='my-4'>
          <input
            type='email'
            placeholder='Email'
            className='text-base my-2 border border-gray-300 text-gray-900 rounded-full focus:outline-slate-500 block w-full p-2.5 '
            {...register('emailInput', {required: true})}
          />
          {errors.emailInput && <div className='text-sm text-red-500'>This field is required.</div>}
        </div>
      </form>
    </div>
  )
}

export const ForgotPasswordDialog = ({open, onClose}: {open: boolean; onClose: () => void}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<DialogTitle state={state} dispatch={dispatch} onClose={onClose} />}
      body={<DialogBody state={state} dispatch={dispatch} />}
      footer={<DialogFooter state={state} dispatch={dispatch} onClose={onClose} />}
    />
  )
}
