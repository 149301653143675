import {useEffect, useState} from 'react'

export default function useMobileAppDetect() {
  const [isMobileApp, setMobileApp] = useState(false)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    setMobileApp(userAgent.includes('CoopMobile'))
  }, [])

  return {isMobileApp}
}

export const useDetectMobile = () => {
  const [mobileDevice, setMobileDevice] = useState(null)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    if (userAgent.match(/Android/i)) return setMobileDevice('android')
    if (userAgent.match(/iPhone|iPad|iPod/i)) return setMobileDevice('iOS')
  }, [])

  return {mobileDevice}
}

export const useIsSafari = () => {
  const [isSafari, setIsSafari] = useState(false)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    return setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent))
  }, [])

  return {isSafari}
}
