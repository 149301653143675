'use client'

import {useState, useEffect} from 'react'

export default function Greeting() {
  const [greet, setGreet] = useState<string | null>(null)

  useEffect(() => {
    const hours = new Date().getHours()

    if (hours < 12) {
      setGreet('morning')
    } else if (hours >= 12 && hours <= 17) {
      setGreet('afternoon')
    } else if (hours > 17 && hours <= 24) {
      setGreet('evening')
    }
  }, [])

  return (
    <h1
      className={`pb-2 text-4xl font-semibold text-white transition-opacity duration-300 ${
        greet ? 'opacity-100' : 'opacity-0'
      }`}
    >
      Good {greet}!
    </h1>
  )
}
