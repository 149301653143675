'use client'

import Cookies from 'js-cookie'
import {Loader2} from 'lucide-react'
import {signIn} from 'next-auth/react'
import Link from 'next/link'
import {useRouter, useSearchParams} from 'next/navigation'
import React, {useEffect, useReducer, useState} from 'react'
import {FieldValues, UseFormRegister, useForm} from 'react-hook-form'

import {Button} from '@/components/ui/button'
import useMobileAppDetect from '@/lib/device-detect'
import {EyeIcon, EyeSlashIcon} from '@heroicons/react/24/outline'

import {ForgotPasswordDialog} from './forgot-password-dialog'

interface FormStateProps {
  error: string
  submitBtnEnabled: boolean
  loading: boolean
  passwordType: 'password' | 'text'
}

type ReducerActionProps =
  | {type: 'setPasswordType'; payload: FormStateProps['passwordType']}
  | {type: 'setSubmitEnabled'; payload: FormStateProps['submitBtnEnabled']}
  | {type: 'setError'; payload: FormStateProps['error']}
  | {type: 'setMultiple'; payload: Partial<FormStateProps>}

const initialFormState: FormStateProps = {
  error: '',
  submitBtnEnabled: true,
  loading: false,
  passwordType: 'password',
}

type FormFields = FieldValues & {email: string; password: string}

const reducer = (state: FormStateProps, action: ReducerActionProps): FormStateProps => {
  switch (action.type) {
    case 'setPasswordType': {
      return {...state, passwordType: action.payload}
    }
    case 'setSubmitEnabled': {
      return {...state, submitBtnEnabled: action.payload}
    }
    case 'setError': {
      return {...state, error: action.payload}
    }
    case 'setMultiple': {
      return {...state, ...action.payload}
    }
    default: {
      return {...state}
    }
  }
}

const TextInput = ({
  name,
  type,
  placeholder,
  register,
}: {
  name: string
  type: string
  placeholder: string
  register: UseFormRegister<FormFields>
}) => {
  return (
    <input
      className='h-12 lg:max-w-[24rem] w-full px-10 py-2 bg-white rounded-full md:text-xl focus:outline-slate-500'
      autoComplete='off'
      type={type}
      placeholder={placeholder}
      {...register(name, {required: true})}
    />
  )
}

const LoginBtn = ({loading}: {loading: boolean}) => {
  return (
    <Button
      type='submit'
      className='text-lg text-slate-900 font-semibold border-none bg-gradient-to-r from-[#00FF8C] to-[#B8FF00] inline-flex items-center justify-center rounded-full'
    >
      {loading ? (
        <>
          <Loader2 className='w-4 h-4 mr-3 animate-spin' />
          <span className='animate-pulse'>Signing in</span>
        </>
      ) : (
        <span>Sign in</span>
      )}
    </Button>
  )
}

const LoginErrorMsg = ({msg}: {msg: string}) => {
  return (
    <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'>{msg}</div>
  )
}

const ViewPasswordToggle = ({
  passwordType,
  togglePassword,
}: {
  passwordType: FormStateProps['passwordType']
  togglePassword: () => void
}) => {
  return (
    <div className='w-6 right-4 mt-[0.75rem] top-0 absolute opacity-25 hover:opacity-75'>
      {passwordType === 'password' ? (
        <EyeIcon
          onClick={(e) => {
            e.preventDefault()
            togglePassword()
          }}
        />
      ) : (
        <EyeSlashIcon
          onClick={(e) => {
            e.preventDefault()
            togglePassword()
          }}
        />
      )}
    </div>
  )
}

const displayError = (error: string) => {
  const knownErrors: {[key: string]: string} = {
    CredentialsSignin: 'Invalid credentials',
  }
  return knownErrors[error] ?? 'Something went wrong'
}

export const LoginForm = ({csrfToken, isEmbedded = false}: {csrfToken: string; isEmbedded?: boolean}) => {
  const [state, dispatch] = useReducer(reducer, initialFormState)
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false)
  const router = useRouter()
  const callbackUrl = useSearchParams().get('callbackUrl')
  const {isMobileApp} = useMobileAppDetect()

  useEffect(() => {
    if (Cookies.get('cookieConsent') === 'false') {
      dispatch({type: 'setSubmitEnabled', payload: false})
    } else {
      dispatch({type: 'setSubmitEnabled', payload: true})
    }
  }, [])

  useEffect(() => {
    // revalidates Router Cache
    router.refresh()
  }, [router])

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormFields>()

  const onSubmit = async (data: {email: string; password: string}) => {
    const cookieConsent = Cookies.get('cookieConsent')

    if (cookieConsent !== 'true') {
      dispatch({
        type: 'setMultiple',
        payload: {error: 'Please accept cookies to continue', submitBtnEnabled: true, loading: false},
      })
      return
    }

    dispatch({type: 'setMultiple', payload: {submitBtnEnabled: false, loading: true, error: ''}})
    const {email, password} = data
    const res = await signIn('credentials', {email, password, domain: window.location.host, redirect: false})
    if (res.error) {
      dispatch({type: 'setMultiple', payload: {submitBtnEnabled: true, loading: false, error: displayError(res.error)}})
      return
    }

    if (!isEmbedded) {
      const callback = callbackUrl ?? '/'
      router.push(callback as string)
      return
    }

    dispatch({type: 'setMultiple', payload: {submitBtnEnabled: true, loading: false}})
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-center justify-center flex-1 w-full space-y-4'
      >
        {state.error && <LoginErrorMsg msg={state.error} />}
        <input name='csrfToken' type='hidden' defaultValue={csrfToken} />
        <TextInput name='email' type='email' placeholder='Email' register={register} />
        <div className='relative w-full'>
          <TextInput name='password' type={state.passwordType} placeholder='Password' register={register} />
          <ViewPasswordToggle
            passwordType={state.passwordType}
            togglePassword={() => {
              dispatch({type: 'setPasswordType', payload: state.passwordType === 'password' ? 'text' : 'password'})
            }}
          />
        </div>
        <p
          className='pb-4 mt-2 ml-1 text-white cursor-pointer text-md text-whit hover:underline'
          onClick={() => {
            setOpenForgotPasswordDialog(true)
          }}
        >
          Forgot password?
        </p>
        <div className='flex flex-col m-auto space-y-2'>
          <LoginBtn loading={state.loading} />
          {!isEmbedded && !isMobileApp && (
            <Link
              className='text-lg text-center text-white border-none bolder hover:underline hover:cursor-pointer'
              href='/auth/register'
            >
              Create Account
            </Link>
          )}
        </div>
      </form>
      <ForgotPasswordDialog
        open={openForgotPasswordDialog}
        onClose={() => {
          setOpenForgotPasswordDialog(false)
        }}
      />
    </>
  )
}
