import clsx from 'clsx'
import React from 'react'
import {overrideTailwindClasses} from 'tailwind-override'

export const Button = ({
  children,
  as: Component = 'button',
  primary = false,
  success = false,
  danger = false,
  disabled = false,
  fullWidth = false,
  small = false,
  medium = false,
  className = '',
  ...props
}) => {
  const classes = overrideTailwindClasses(
    clsx(
      'px-5 py-2 font-medium text-sm md:text-md lg:text-xl truncate border-2 rounded-full text-primary border-primary hover:bg-slate-900 hover:text-white hover:bg-primary-dark hover:border-slate-900',
      primary && 'bg-primary text-white',
      success && 'bg-green-600 text-white border-green-600 hover:bg-green-700 hover:border-green-700',
      danger && 'text-white bg-orange-600 border border-transparent hover:bg-orange-900 focus:outline-none',
      disabled && [
        'border-slate-400 hover:border-slate-400 cursor-not-allowed text-slate-600',
        'hover:bg-transparent hover:text-slate-600',
        primary && 'bg-slate-400 hover:bg-slate-400',
        success && 'bg-green-300 hover:bg-green-300 hover:border-green-300 border-green-300 text-slate-400',
        danger && 'bg-orange-500 text-white hover:bg-orange-500 hover:text-white',
      ],
      small && 'px-4 py-1.5 mt-2 text-xs md:text-xs lg:text-xs border',
      medium && 'px-4 py-2 mt-2 text-sm md:text-sm lg:text-sm',
      fullWidth && 'w-full sm:max-w-xs',
      className
    )
  )

  return (
    <Component className={classes} {...props} disabled={disabled}>
      {children}
    </Component>
  )
}
