'use client'

import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import {useEffect, useState} from 'react'

import {Button} from '@/components/ui/button'
import useMobileAppDetect from '@/lib/device-detect'
import cookieCooper from '@/public/cookieCooper.png'

export const CookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState(true)
  const {isMobileApp} = useMobileAppDetect()
  if (isMobileApp) {
    Cookies.set('cookieConsent', 'true')
  }

  const cookieConsentClick = () => {
    setCookieConsent(true)
    Cookies.set('cookieConsent', 'true')
  }
  useEffect(() => {
    if (Cookies.get('cookieConsent') !== 'true') {
      setCookieConsent(false)
    }
  }, [])

  if (cookieConsent) return null

  return (
    !cookieConsent && (
      <div className='absolute inset-0 flex items-center justify-center'>
        <div className='fixed top-0 z-10 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-80 sticky-0'>
          <div className='p-4 min-w-[50%] min-h-[50%] flex justify-center items-center'>
            <div className='relative flex flex-col items-center justify-center w-full h-full p-4 bg-white rounded-lg md:w-3/4 dark:bg-gray-800'>
              <Image src={cookieCooper} alt='Cookie Cooper' className='object-contain w-full ml-8 max-w-64' priority />
              <div>
                <h1 className='text-2xl font-semibold text-center dark:text-white lg:text-3xl'>Cooper loves cookies</h1>
              </div>
              <div className='my-5'>
                <p className='text-sm leading-5 text-center dark:text-white'>
                  This site uses services that uses cookies to deliver better experience and analyze traffic.
                  <br></br>
                  You can learn more about the services we use at our{' '}
                  <Link
                    href='https://help.knowledgecoop.com/en/knowledge/knowledge-coop-privacy-policy'
                    target='_blank'
                    className='text-xs underline hover:text-lightAccent'
                  >
                    privacy policy
                  </Link>
                  .
                </p>
              </div>
              <Button variant='default' className='w-full' onClick={() => cookieConsentClick()}>
                Accept
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
