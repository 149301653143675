export const associateDomain = (domain: string) => {
  if (domain.endsWith('dev')) {
    return `${domain.split('.dev')[0]}.com`
  }
  const envDomain = process.env.NEXT_PUBLIC_INSTANCE_URL?.split('://')?.[1]
  if (domain.endsWith('.vercel.app')) {
    return envDomain || 'test-tkc.knowledgecoop.com'
  }

  if (process.env.NODE_ENV === 'development') {
    return decodeURIComponent(envDomain || 'localhost:8000')
  }

  if (process.env.NODE_ENV === 'production' && domain.startsWith('localhost')) {
    return decodeURIComponent(envDomain)
  }

  const knownDomains = {
    'staging.knowledgecoop.com': 'test-tkc.knowledgecoop.com',
    'app.knowledgecoop.com': 'tkc.knowledgecoop.com',
  }

  return knownDomains[domain] || domain
}

export const getBaseUrl = ({
  sessionDomain = undefined,
  requestDomain = undefined,
}: {
  sessionDomain?: string
  requestDomain?: string
} = {}) => {
  const protocol = process.env.NEXT_PUBLIC_DEV_PROTOCOL ?? 'https'

  if (sessionDomain) {
    return `${protocol}://${sessionDomain}`
  }

  if (requestDomain) {
    const domain = associateDomain(requestDomain)
    return `${protocol}://${domain}`
  }

  if (typeof window !== 'undefined') {
    const domain = associateDomain(window.location.host)
    return `${protocol}://${domain}`
  }

  return process.env.NEXT_PUBLIC_INSTANCE_URL
}
