import {overrideTailwindClasses} from 'tailwind-override'
import clsx from 'clsx'

export const Spinner = ({className = ''}) => {
  const classes = overrideTailwindClasses(clsx('w-5 h-5 text-white animate-spin', className))
  return (
    <svg className={classes} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle className='opacity-50' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
      <path
        className='opacity-90'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      />
    </svg>
  )
}
